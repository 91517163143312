.exam-container {
  width: 100%;

  margin: auto;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
}

/* Make sure exam content fits well on mobile */
@media (max-width: 600px) {
  .exam-container {
    width: 100%;
    border-radius: 0;
    padding: 10px;
  }
}

/* Question Box */
.question-box {
  background: #f8f9fa;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Option Box */
.option-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}

/* Tabs (Subjects Navigation) */
.nav-tabs {
  display: flex;
  flex-wrap: nowrap; /* Prevent tabs from wrapping */
  overflow-x: auto; /* Allow scrolling if too many subjects */
  white-space: nowrap;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.nav-item button {
  flex-shrink: 0;
  min-width: 80px;
  font-size: 14px;
  padding: 8px 12px;
}

/* Buttons */
.btn {
  font-size: 16px;
  padding: 10px 14px;
  border-radius: 5px;
}

/* Bigger buttons on mobile */
@media (max-width: 600px) {
  .btn {
    font-size: 14px;
    padding: 12px 14px;
  }
}

/* Question Navigation (Previous / Next) */
.d-flex.gap-2 {
  flex-wrap: wrap; /* Ensure buttons stack if needed */
  justify-content: center;
}

@media (max-width: 600px) {
  .d-flex.gap-2 {
    flex-direction: column;
  }
  .btn {
    width: 100%; /* Full width buttons */
    text-align: center;
  }
}

/* Pagination (Question Numbers) */
.mt-4 {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 10px;
  justify-content: center;
}

.btn-lg {
  font-size: 14px;
  padding: 8px 12px;
  min-width: 40px;
}

/* Custom Radio Button */
.custom-radio {
  width: 20px;
  height: 20px;
  border: 2px solid black;
  appearance: none;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.custom-radio:checked {
  background-color: black;
}

/* Modals */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 90%;
  max-width: 400px;
}

.modal-buttons {
  display: flex;
  flex-direction: column;
}

.modal-buttons .btn {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .time-submit-container {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .time-submit-container h3 {
    font-size: 1.2rem; /* Smaller font size */
  }

  .time-submit-container button {
    width: 100%; /* Full width button */
    font-size: 1rem;
  }
}
